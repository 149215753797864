import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AddForm } from './components/AddForm/AddForm';
import { SearchContext } from './components/context/search.context';
import { Header } from './components/layouts/Headers';
import { Map } from './components/Map/Map';

export const App = () => {

  const [search, setSearch] = useState('');

  return (
    <>
      <SearchContext.Provider value={{search, setSearch}}>
        <Header />
        <Routes>
          <Route path="/" element={<Map />}/>
          <Route path="/add" element={<AddForm />}/>
        </Routes>
      </SearchContext.Provider>
    </>
  );
};
